<template>
  <div class="top_tababr">
    <div class="top_cnter">
      <div class="top_leftbar">
        <div class="top_logotitle">重庆哈工大紫丁香资源共享服务平台</div>
        <div class="top_btmctx">
          <div class="logoin">
            <img
              class="logo1"
              src="../assets/image/logo_new2.png"
              @click="gouhome()"
              alt=""
            />
            <img
              class="Schoolbadge"
              @click="gouhome()"
              src="../assets/image/logo_new1.png"
              alt=""
            />
          </div>
          <div class="search">
            <div class="search_center">
              <div class="switch">
                <div
                  :class="styleshow == true ? 'switch_qies' : 'switch_qie'"
                  @click="jump()"
                >
                  资源
                </div>
                <div
                  :class="styleshow == false ? 'switch_qies' : 'switch_qie'"
                  style="margin-left: 10px"
                  @click="jump()"
                >
                  企业
                </div>
              </div>
              <div class="search_input">
                <el-input v-model="input" placeholder="请输入关键词"></el-input>
                <div class="search_iocn" @click="getsearch()">
                  <img src="../assets/image/search.png" class="icon" alt="" />
                  <span>搜索</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Real-time">
        <h3>平台实时数据</h3>
        <div class="Real-shuju">
          <ul>
            <li>
              <div class="headline">
                <div class="lines"></div>
                <p>{{ realData.enterprisequantityname }}</p>
              </div>
              <div class="figure">
                <countTo
                  :startVal="startVal"
                  :endVal="parseInt(realData.enterprisequantity)"
                  :duration="4000"
                ></countTo>
                <p>家</p>
              </div>
            </li>
            <li>
              <div class="headline">
                <div class="lines"></div>
                <p>{{ realData.numberofenterprisesname }}</p>
              </div>
              <div class="figure">
                <countTo
                  :startVal="startVal"
                  :endVal="parseInt(realData.numberofenterprises)"
                  :duration="4000"
                ></countTo>
                <p>家</p>
              </div>
            </li>
            <li>
              <div class="headline">
                <div class="lines"></div>
                <p>{{ realData.resourceproductname }}</p>
              </div>
              <div class="figure">
                <countTo
                  :startVal="startVal"
                  :endVal="parseInt(realData.resourceproduct)"
                  :duration="4000"
                ></countTo>
                <p>个</p>
              </div>
            </li>
          </ul>
          <ul>
            <li>
              <div class="headline">
                <div class="lines"></div>
                <p>{{ realData.numberofservicesname }}</p>
              </div>
              <div class="figure">
                <countTo
                  :startVal="startVal"
                  :endVal="parseInt(realData.numberofservices)"
                  :duration="4000"
                ></countTo>
                <p>次</p>
              </div>
            </li>
            <li>
              <div class="headline">
                <div class="lines"></div>
                <p>{{ realData.patentachievementname }}</p>
              </div>
              <div class="figure">
                <countTo
                  :startVal="startVal"
                  :endVal="parseInt(realData.patentachievement)"
                  :duration="4000"
                ></countTo>
                <p>项</p>
              </div>
            </li>
            <li>
              <div class="headline">
                <div class="lines"></div>
                <p>{{ realData.experttalentname }}</p>
              </div>
              <div class="figure">
                <countTo
                  :startVal="startVal"
                  :endVal="parseInt(realData.experttalent)"
                  :duration="4000"
                ></countTo>
                <p>位</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <div class="top_Navigation">
      <Navigation></Navigation>
    </div>
    <search
      :dialogVisible.sync="dialogVisible"
      :Search_content="content"
    ></search>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import Navigation from "./Navigation.vue";
import search from "./search.vue";
import { OrderService } from "../api/product";
export default {
  components: { countTo, Navigation, search },
  props: [""],

  data() {
    return {
      styleshow: true,
      input: "",
      startVal: 0,
      autoplay: false,
      realData:{
        "experttalentname": "入驻专家人才",
        "experttalent": "31",
        "numberofservicesname": "服务次数",
        "numberofservices": "214",
        "enterprisequantityname": "企业数量",
        "enterprisequantity": "112",
        "resourceproductname": "入驻服务资源产品",
        "resourceproduct":'12',
        "patentachievementname": "企业专利成果",
        "patentachievement": "287",
        "numberofenterprises": "102",
        "numberofenterprisesname": "入驻企业数量",       
      },
      Real_times: [
        {
          name: "服务次数",
          shuzi: "12999",
          unit: "次",
        },
        {
          name: "企业专利成果",
          shuzi: "12999",
          unit: "项",
        },
        {
          name: "入驻专家人才",
          shuzi: "12999",
          unit: "位",
        },
      ],
      dialogVisible: false,
      content: "",
    };
  },
  mounted() {
    this.getRealData();
  },
  methods: {
    getRealData(){
      OrderService.getRealData().then((res) => {
        console.log('res',res);
        if (res.code == 200) {
          this.realData = res.data[0];
        } else {
        }
      });
    },
    jump() {
      this.styleshow = !this.styleshow;
    },
    getsearch() {
      // this.dialogVisible =true
      this.content = this.input;
      this.$store.state.current_user_name = this.content;
      switch (this.styleshow) {
        case true:
          sessionStorage.setItem("page_id", 3);
          if (this.content != "") {
            var lists = {
              type: "全部",
            };
            this.$router.push({ name: "Service_resource", query: lists });
          } else {
          }
          break;
        case false:
          sessionStorage.setItem("page_id", 4);
          if (this.content != "") {
            var lists = {
              type: "全部",
            };
            this.$router.push({ name: "Qiye_resource", query: lists });
          } else {
          }
          break;

        default:
          break;
      }

      this.input = "";
    },
    gouhome() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>


<style scoped lang="scss">
.top_tababr {
  width: 100%;
  background: #fff;
}
.top_cnter {
  width: 80%;
  // height: 151px;
  padding: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .top_leftbar {
    width: 80%;
    .top_logotitle {
      height: 30px;
      line-height: 30px;
      font-size: 24px;
      margin-bottom: 10px;
      font-family: initial;
      font-weight: 700;
    }
    .top_btmctx {
      display: flex;
      .logoin {
        // width: 40%;
        display: flex;
        justify-content: left;
        align-items: center;
        // margin-top: 22px;
        .logo1 {
          width: 110px;
          // height: 60px;
          cursor: pointer;
        }
        .Schoolbadge {
          cursor: pointer;
          margin: 0 20px;
          width: 225px;
        }
      }
      .search {
        width: calc(100% - 380px);
        display: flex;
        cursor: pointer;
        .search_center {
          width: 95%;
          .search_input {
            display: flex;
            justify-content: center;
          }
          .search_iocn {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #5d207c;
            height: 40px;
            width: 80px;

            .icon {
              width: 15px;
              height: 15px;
              margin-right: 10px;
            }
            span {
              color: #fff;
              font-size: 14px;
            }
          }
        }
        .switch {
          display: flex;
          // padding: 10px;

          .switch_qie {
            color: rgb(163, 163, 163);
            // background-color: #5D207C;
            padding: 5px 10px;
            font-size: 14px;
          }
        }
        .switch_qies {
          background: #5d207c;
          color: #fff;
          padding: 5px 10px;
          font-size: 14px;
          transform: scale(1);
          transform-origin: left;
          transition: transform 0.2s ease-in-out;
        }
      }
    }
  }

  .Real-time {
    width: 290px;
    max-width: 400px;
  }
}
.Real-time {
  // height: 100%;
  text-align: center;
  background-color: #f7f4f8;
  margin-left: 10px;
  h3 {
    margin: 8px;
    padding: 0px;
    font-size: 14px;
  }
  .Real-shuju {
    display: flex;
    justify-content: space-around;
  }
  ul {
    li {
      // width: 100px;
      .lines {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #5d207c;
      }
      .headline {
        display: flex;
        justify-content: left;
        align-items: center;
      }
      .figure {
        display: flex;
        justify-content: left;
        align-items: flex-end;
        // margin-left: 5px;
        margin: 5px;
        p {
          -webkit-text-size-adjust: none;
          transform: scale(0.733333);
          font-size: 6px;
        }
      }

      p {
        margin: 0;
        padding: 0;
        -webkit-text-size-adjust: none;
        transform: scale(0.833333);
        // margin-left: 5px;
        font-size: 7px;
      }
      span {
        font-size: 14px;
        font-weight: bold;
        color: #5d207c;
        margin-left: 5px;
      }
    }
  }
}
.top_Navigation {
  width: 80%;
  // height: 151px;
  // padding: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
::v-deep .search_input .el-input .el-input__inner {
  border-radius: 0px !important;
  border: 1px solid #5d207c;
}
::v-deep .el-button {
  border-radius: 0px !important;
  background: #5d207c;
  border: 1px solid #5d207c;
}
</style>
